import React from 'react';
import LayoutES from '../components/layout.es';
import CTAButton from '../components/ctabutton.component';
import Seo from '../components/seo.es';

import logoJobs from "../static/images/logo-jobs-partners.svg";
import emailImg from "../static/images/email-image.png";
import '../styles/jobs.scss';

export default function Jobs() {
  return (
    <LayoutES>
      <Seo title="Trabaja con nosotros" />
      <section id="introJobs">
        <div className="introJobs-columns">
          <div id="introJobs-text" className="introJobs-column">
            <h1>Trabaja con nosotros</h1>
            <h2>Únete a nuestro equipo</h2>
          </div>
        </div>
      </section>

      <div className="logo-jobs">
        <div className="logo-container">
          <img src={logoJobs} alt="Jobs partners" />
        </div>
      </div>

      <section id="mainJob" style={{ marginBottom: '1rem' }}>
        <div className='container'>
          <h2>Ingeniero de software</h2>
          <h3>Acerca del empleo</h3>
          <span>
            <p>
              En <strong>KuFlow </strong>ayudamos a cualquier organización a
              diseñar y ejecutar procesos en donde participen personas, robots y
              otras aplicaciones. Es una <strong>plataforma SaaS</strong> que
              acaba de lanzarse y buscamos personas que quieran unirse a nosotros.
              Si estás buscando un nuevo reto en una <strong>startup </strong>que
              está empezando, si quieres participar desde un momento muy
              incipiente en un proyecto ilusionante, habla con nosotros.
            </p>
            <p>
              <strong>¿Qué harás?</strong>
            </p>
            <ul>
              <li>
                Crearás conectores que integren KuFlow con otras plataformas
                externas: Jira, Slack, herramientas de Google, etc.
              </li>
              <li>
                Participarás en proyectos concretos que ayuden a implantar la
                plataforma de forma exitosa en los clientes.
              </li>
              <li>Diseñarás RPAs con distintas soluciones.</li>
              <li>
                Tendrás voz y voto en las decisiones que marquen el futuro de la
                plataforma.
              </li>
            </ul>

            <p>
              <strong>¿Qué esperamos de ti?</strong>
            </p>
            <ul>
              <li>Que te ilusione el proyecto.</li>
              <li>
                Que quieras participar proactivamente en el desarrollo de la
                plataforma.
              </li>
              <li>
                Que te motive la idea de implantar la plataforma en clientes y ver
                cómo mejora su desempeño día a día.
              </li>
            </ul>
            <p>
              <strong>¿Qué necesitamos?</strong>
            </p>
            <ul>
              <li>
                Personas formadas en el desarrollo de software (Grado
                universitario o Ciclo formativo de Grado Superior).
              </li>
              <li>
                En general buscamos personas generalistas (backend y frontend),
                pero estamos abiertos a perfiles que sólo quieran trabajar en
                backend.
              </li>
              <li>
                Pasión por la tecnología y cómo ésta puede llegar a aportar valor
                a los clientes.
              </li>
              <li>
                Trabajo en equipo: pedir ayuda y saber prestarla cuando sea
                necesario.
              </li>
              <li>
                Capacidad para entender las necesidades de negocio y proactividad
                para sugerir soluciones colaborando con diferentes puntos de
                vista.
              </li>
              <li>Sería deseable un buen nivel de inglés.</li>
            </ul>
            <p>
              <strong>¿Qué ofrecemos?</strong>
            </p>
            <ul>
              <li>
                Salario: entre 25K (si no tienes experiencia) y 40K (varios años
                de experiencia).
              </li>
              <li>23 días de vacaciones.</li>
              <li>
                Trabajo remoto 100%, pero siempre con la posibilidad de acudir a
                la oficina de Tenerife cuando quieras.
              </li>
              <li>
                Horario flexible (sólo pedimos sentido común para que coincidas
                con el resto del equipo).
              </li>
              <li>Seguro Médico con cobertura dental.</li>
            </ul>
          </span>
          <CTAButton inverted mail mailaddress="jobs@kuflow.com" color='lblue'>
            Lo quiero
		  </CTAButton>
          <div className='email-box'>
            <span>Envíanos tu CV al siguiente email: </span>
            <img src={emailImg} alt="Email" />
          </div>
        </div>

      </section>
    </LayoutES>
  );
}
